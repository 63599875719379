@import url('https://cdn.jsdelivr.net/gh/hung1001/font-awesome-pro@0ac23ca/css/all.css');
html,
body,
#root {
    height: 100%;
    overflow: hidden;
}

body {
    background-color: #f9fafc;
}

a {
    cursor: pointer;
}

img{
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast
}
.red{
    color: red;
}

.editor-container {
    display: flex;
    margin: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow: hidden;
}

.editor-container .form-definition-heading {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 15px;
    right: 15px;
    background-color: #f9fafc;
}

.editor-container .form-definition-heading h2 {
    border-bottom: 1px solid #e0eee0;
    color: #3B4859;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    display: block;
    margin-bottom: 2px;
}

.editor-container .left-side {
    width: 200px;
    height: calc(100% - 65px);
    overflow: hidden;
    background-color: #3B7CFF26;
    padding: 10px 5px;
    margin-bottom: 15px;
    border-radius: 0px;
    box-shadow: 0px 2px 4px #EFF2F7;
    position: fixed;
    left: 15px;
    top: 50px;
    z-index: 1;
}

.editor-container .left-side ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.editor-container .left-side ul li {
    display: flex;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 44%;
    height: 84px;
    margin: 0 5px 10px;
    padding: 5px;
    color: #ffffff;
    cursor: pointer;
    background-color: #3b7cff;
}

.editor-container .left-side ul li span {
    display: block;
    width: 100%;
    padding-bottom: 5px;
    font-size: 24px;
    line-height: 28px;
}

.editor-container .left-side ul li p {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
}

.editor-container .right-side {
    width: 200px;
    height: calc(100% - 65px);
    background-color: #E8E8E8;
    padding: 3px;
    border-radius: 0px;
    box-shadow: 0px 2px 4px #EFF2F7;
    position: fixed;
    right: 15px;
    top: 50px;
    z-index: 1;
}

.editor-container .right-side .tabs {
    background-color: #ffffff;
    padding: 5px 0px 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
}

.editor-container .right-side .tabs li {
    border: 1px solid #E8E8E8;
    border-bottom: none;
    background-color: #ffffff;
    color: #323C47;
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2px;
    margin-right: 2px;
}

.editor-container .right-side .tabs li.active {
    background-color: #E8E8E8;
}

.editor-container .right-side .tab-container {
    padding: 20px 0;
    height: calc(100% - 32px);
}

.editor-container .right-side .properties-tab-container .tab-editor {
    display: block;
    padding-bottom: 15px;
}

.editor-container .right-side .properties-tab-container .tab-editor strong {
    font-size: 14px;
    font-weight: 600;
    color: #323C47;
    line-height: 20px;
    display: block;
    padding-bottom: 5px;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input {
    display: block;
    width: 100%;
    margin-bottom: 5px;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group {
    display: block;
    width: 100%;
    margin-bottom: 0;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group label {
    display: inline-block;
    color: #B9BDC1;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    margin-right: 7px;
    margin-bottom: 0;
    vertical-align: top;
    width: 15px;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak label {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control {
    display: inline-block;
    border: none;
    background-color: #E8E8E8;
    border-bottom: 1px solid #B9BDC1;
    padding: 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;
    height: 18px;
    border-radius: 0px;
    color: #B9BDC1;
    width: 30px;
    text-align: center;
    vertical-align: top;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak .form-control {
    color: #323C47;
    border-color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control::-webkit-input-placeholder {
    color: #B9BDC1;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control::-moz-placeholder {
    color: #B9BDC1;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control:-ms-input-placeholder {
    color: #B9BDC1;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control:-moz-placeholder {
    color: #B9BDC1;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak .form-control::-webkit-input-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak .form-control::-moz-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak .form-control:-ms-input-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group.drak .form-control:-moz-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .transform-input .form-group .form-control:focus {
    box-shadow: none;
}

.editor-container .right-side .properties-tab-container .tab-editor .select {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    background-color: #E8E8E8;
}

.editor-container .right-side .properties-tab-container .tab-editor .select::after {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    content: '\f107';
    font-family: 'FontAwesome';
    font-weight: 600;
    color: #323C47;
    font-size: 16px;
    line-height: 22px;
}

.editor-container .right-side .properties-tab-container .tab-editor .select select {
    display: block;
    width: 100%;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #707070;
    padding: 3px 20px 3px 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #323C47;
    text-indent: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 2;
}

.editor-container .right-side .properties-tab-container .tab-editor .select select:focus-visible {
    outline: none;
}


.editor-container .right-side .properties-tab-container .tab-editor .select .input-box {
    width: 20px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .select-box {
    display: block;
    width: calc(100% - 30px);
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control {
    display: block;
    width: 100%;
    border: none;
    background-color: #E8E8E8;
    border-bottom: 1px solid #707070;
    padding: 0px 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;
    height: 26px;
    border-radius: 0px;
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control::-webkit-input-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control::-moz-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control:-ms-input-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control:-moz-placeholder {
    color: #323C47;
}

.editor-container .right-side .properties-tab-container .tab-editor .select .input-box .form-control:focus {
    box-shadow: none;
}

.editor-container .right-side .properties-tab-container .tab-editor .buttons {
    display: block;
}

.editor-container .right-side .properties-tab-container .tab-editor .buttons .btn {
    padding: 0 2px;
}

.editor-container .right-side .properties-tab-container .tab-editor .buttons .btn:focus {
    box-shadow: none;
}

.editor-container .right-side .display-tab-container .title {
    color: #323C47;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 15px;
}

.editor-container .right-side .display-tab-container .form-check .form-check-input {
    cursor: pointer;
    margin-top: .2rem;
}

.editor-container .right-side .display-tab-container .form-group {
    margin-bottom: 0.7rem;
}

.editor-container .right-side .display-tab-container .form-group label {
    margin-bottom: 0;
    color: #3B4859;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    display: block;
}

.editor-container .right-side .display-tab-container .form-group label .btn {
    padding: 0;
    color: #f93d3d;
    font-size: 12px;
}

.editor-container .right-side .display-tab-container .form-group label .btn:focus {
    box-shadow: none;
}

.editor-container .right-side .display-tab-container .form-group .form-control {
    background-color: #F1F2F5;
    border: 1px solid #D1DAE2;
    height: 28px;
    border-radius: 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #777B7F;
    padding: 4px 6px;
}

.editor-container .right-side .display-tab-container .form-group textarea.form-control {
    height: 60px;
}

.editor-container .right-side .display-tab-container .form-group .form-control:focus {
    box-shadow: none;
}

.editor-container .right-side .display-tab-container .form-group ul {
    padding: 0;
    margin: 3px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #2662F0;
    display: flex;
}

.editor-container .right-side .display-tab-container .form-group ul li {
    display: block;
    color: #45494D;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    width: 50%;
    cursor: pointer;
}

.editor-container .right-side .display-tab-container .form-group ul li.active {
    background-color: #2662F0;
    color: #ffffff;
}

.editor-container .right-side .display-tab-container .display-type-box .form-group {
    margin-bottom: 0.2rem;
}

.editor-container .right-side .display-tab-container .btn-add-new {
    font-size: 11px;
    font-weight: 400;
    border-radius: 10px;
    padding: 0;
    width: 96px;
    height: 28px;
    text-align: center;
    color: #ffffff;
    background-color: #2662f0;

}

.editor-container .right-side .display-tab-container .btn-add-new:focus {
    box-shadow: none;
}

.editor-container .right-side .display-tab-container .display-type-box .form-group .form-check {
    padding-left: 0.8rem;
}

.editor-container .right-side .display-tab-container .display-type-box .form-group .form-check .form-check-input {
    position: relative;
    margin-top: 0;
    margin-left: 0;
}

.editor-container .right-side .actions-tab-container .actions-buttons {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
}

.editor-container .right-side .actions-tab-container .actions-buttons .btn {
    display: flex;
    border: 0;
    padding: 0;
    font-size: 14px;
    color: #3b4859;
    font-weight: 400;
    line-height: 24px;
}

.editor-container .right-side .actions-tab-container .actions-buttons .btn:hover {
    color: #3979fa;
}

.editor-container .right-side .actions-tab-container .actions-buttons .btn:focus {
    box-shadow: none;
}

.editor-container .right-side .actions-tab-container .actions-links {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #d1d1d1;
}

.editor-container .right-side .actions-tab-container .actions-links.last {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.editor-container .right-side .actions-tab-container .actions-links strong {
    color: #323C47;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
}

.editor-container .right-side .actions-tab-container .actions-links ul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.editor-container .right-side .actions-tab-container .actions-links ul li {
    display: block;
    padding-bottom: 10px;
}

.editor-container .right-side .actions-tab-container .actions-links ul li a {
    font-size: 14px;
    color: #3b4859;
    font-weight: 400;
    line-height: 24px;
}

.editor-container .right-side .actions-tab-container .actions-links ul li a:hover {
    text-decoration: none;
    color: #3979fa;
}


.editor-container .center-container {
    width: calc(100% - 400px);
    height: calc(100% - 35px);
    margin-top: 35px;
    margin-left: 200px;
    margin-right: 200px;
    padding-left: 15px;
    padding-right: 15px;
}

.editor-container .center-container .center-heading-box {
    border-bottom: 1px solid #e0eee0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 20px;
}

.editor-container .center-container .center-heading-box h3 {
    color: #3b4859;
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
}

.editor-container .center-container .center-heading-box .btn {
    font-size: 11px;
    font-weight: 400;
    border-radius: 10px;
    padding: 0;
    width: 96px;
    height: 28px;
    text-align: center;
    color: #ffffff;
    background-color: #2662f0;
}

.editor-container .center-container .center-heading-box .btn:focus {
    box-shadow: none;
}

.editor-container .center-container .center-heading-box .btn.preview-btn {
    margin-right: 10px;
}

.editor-container .center-container .center-heading-box .btn.save-btn {
    margin-right: 10px;
}



.nav-tabs .nav-item {
    margin-bottom: -2px;
    position: relative;
}

.nav-tabs .nav-link {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #dddddd #dddddd #dddddd;
}

.nav-tabs .nav-link i {
    padding: 0 3px;
    vertical-align: middle;
    margin-top: -4px;
}

.nav-tabs .nav-link i.edit {
    padding-top: 2px;
}

.nav-tabs .tabs-tolltip {
    position: absolute;
    z-index: 10;
    left: 70%;
    top: 28px;
    opacity: 0;
    width: 150px;
}

.nav-tabs .nav-item .active i:hover+.tabs-tolltip {
    opacity: 1;
}

.nav-tabs .tabs-tolltip ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.form-control.file {
    padding: .18rem .75rem;
    font-size: 0.8rem;
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}

.form-control.tab-edit {
    height: 21px;
    font-size: 12px;
    width: 100px;
    display: inline-block;
    padding: 5px 7px;
}

.question-container-inner .form-group{
    width: 270px;
}

.table table{
    border: 1px solid #707070;
    border-top: none;
} 
.table table td{
    padding: 5px;
    text-align: center;
    border-color: #707070;
    border-right-width: 1px;
    border-right-style: solid;
    background-color: #ffffff;
}
.table table td .btn{
    font-size: 13px;
}
.hyperlink{
    line-height: 36px;
}

.nav-tabs .nav-item{
    margin-right: 5px;
    background-color: #ffffff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: #2662f0;
    color: #ffffff;
}

.custom-file-label::after{
    background-color: #3b7cff;
    color: #ffffff;
}
.custom-file-input:lang(en)~.custom-file-label::after{
    content: "Choose Files";
}
.btn:focus{
    box-shadow: none;
}
.ratings .fa-star{
    cursor: pointer;
}
.ratings .fa-star.active{
    color: #FFCC00;
}
.line-form-control{
    border: none;
    border-bottom: 2px solid #707070;
    border-radius: 0;
    background-color: transparent;
}
.line-form-control:focus{
    box-shadow: none;
    background-color: transparent;
    
}
